import { Button, Form, Input, Select, Table } from "antd"
import { useContext, useEffect, useState } from "react"
import { UserContext } from "./App"
import { io } from "socket.io-client"
import {DownloadOutlined} from '@ant-design/icons';
import { CSVLink } from 'react-csv';

function moisPrec(){
  var dateActuelle = new Date();
var moisPrecedent = dateActuelle.getMonth();
if (moisPrecedent == 0)moisPrecedent = 12; 

console.log(dateActuelle.getFullYear()+"-"+(moisPrecedent<10?"0":"")+moisPrecedent+"-"+(dateActuelle.getDate()<10?"0":"")+dateActuelle.getDate())
return dateActuelle.getFullYear()+"-"+(moisPrecedent<10?"0":"")+moisPrecedent+"-"+(dateActuelle.getDate()<10?"0":"")+dateActuelle.getDate()
}

function Statistic(){
const [debut, setDebut] = useState(moisPrec())
const [fin, setFin] = useState(new Date().toISOString().substr(0,10))
const [seances, setSeances] = useState([])
const [mesSeances, setMesSeances] = useState([])
const [patients, setPatients] = useState([])
const [lesprofils, setLesprofils] = useState([])
const [medecins, setMedecins] = useState([])
const [medecinsFilter, setMedecinsFilter] = useState([])
const [lesCas, setLesCas] = useState([])
const [casFilter, setCasFilter] = useState([])
const { profils,host,cas,user } = useContext(UserContext)
const socket=io()


const columns = [
  {
    title: 'ID',
    render:(ln)=>ln.id  
  },
  {
    title: 'Patient',
    render:(ln)=>ln.patient_nom+" "+ln.patient_prenom  
  },{
    title: 'Date de naissance',
    render:(ln)=>patients.filter(f=>f.id==ln.idPatient).length?new Date((patients.find(f=>f.id==ln.idPatient)).date_naissance).toLocaleDateString():""
  },
  {
    title: 'Medecin',
    render:(ln)=>ln.medecin_nom+" "+ln.medecin_prenom  
  },
  {
    title: 'Profil',
    render:(ln)=>medecins.filter(f=>f.id==ln.idMedecin).length?profils.find(f=>f.value==medecins.find(f=>f.id==ln.idMedecin).profil).label:""
  },
  {
    title: 'Rapport',
    render:(ln)=>ln.rapport
  },
  {
    title: 'Date de seance',
    render:(ln)=>new Date(ln.ladate).toLocaleDateString()
  },
  {
    title: 'Cas',
    render:(ln)=>cas.data[ln.cas-0].cas
  },
]
function charger(){
    fetch(host+"/lireSeancesFiltre/"+debut+fin)
    .then(res => res.json())
    .then(data => {
      setSeances(data);
      setMedecins([...new Set(data.map(m=>(m.idMedecin)))].map(m=>({id:m,nom:data.find(f=>f.idMedecin==m).medecin_nom,prenom:data.find(f=>f.idMedecin==m).medecin_prenom,profil:data.find(f=>f.idMedecin==m).profil})))
      setLesCas([...new Set(data.map(m=>m.cas))])
      setLesprofils(profils.filter(f=>[...new Set(data.map(m=>m.profil))].includes(f.value)))
    })
    .catch(err => console.log(err));
    
    
    
  }

  useEffect(() => {
    charger()
    //alert('autre')
    socket.on('MAJ',()=>{
      
      charger()
    })
     
   
  }, [])
  
  useEffect(() => {
    charger()
    socket.on("MAJ",()=>{
        charger()
      })
  }, [debut,fin])

  useEffect(() => {
    setMesSeances(seances.filter(f=>(casFilter.includes(f.cas) && medecinsFilter.includes(f.idMedecin))))
    setMesSeances(f=>f.map(m=>({
      id:m.id,
      medecin:"Dr "+m.medecin_nom+""+m.medecin_prenom,
      profil:profils.find(f=>f.value==m.profil).label,
      malade:m.patient_nom+" "+m.patient_prenom,
      cas:cas.data[m.cas-0].cas,
      rapport:m.rapport,
      Date:m.ladate
    })))
    console.log("mes seances",mesSeances)
  }, [seances,casFilter,medecinsFilter])
  
return (
    <>
    <Form
    name="basic"
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 16,
    }}
    style={{
      maxWidth: 600,
    }}>
      <Form.Item label="Debut">
        <Input style={{width: '90%',}} type="date" value={debut} onChange={e=>{console.log(e.target.value);setDebut(e.target.value)}} /><br/>
      </Form.Item>
      <Form.Item label="Fin">
        <Input style={{width: '90%',}} type="date" value={fin} onChange={e=>setFin(e.target.value)} /><br/>
      </Form.Item>
      <Form.Item label="Medecin">
        <Select
          showSearch
          onSearch={str=>medecins.map(m=>m.nom+" "+m.prenom+":"+m.profil).includes(str)}
          mode="multiple"
          value={medecinsFilter}
          allowClear
          onChange={setMedecinsFilter}
          style={{width: '90%',}}
          options={lesprofils.map(prf=>({
            label:prf.label,
            options:medecins.filter(f=>f.profil==prf.value).map(e=>{return{value:e.id,label:e.nom+" "+e.prenom+":"+e.profil}})
          }))}
        /><br/>
      </Form.Item>
      <Form.Item label="Cas Obligatoire">
        <Select
          showSearch
          onSearch={str=>cas.data.map(m=>m.cas).includes(str)}
          mode="multiple"
          value={casFilter}
          allowClear
          onChange={setCasFilter}
          style={{width: '90%',}}
          options={profils.map(prf=>({
            label:prf.label,
            options:lesCas.filter(f=>cas.data[f].profil==prf.value).map(e=>{return{value:e,label:cas.data[e].cas}})
          }))}
        /><br/>
        </Form.Item>
    <Button icon={<DownloadOutlined />}>
    <CSVLink separator=";" data={mesSeances} filename="export.csv">
      Exporter
    </CSVLink>
    
    </Button>
    </Form>
    <Table dataSource={seances.filter(f=>(casFilter.includes(f.cas) && medecinsFilter.includes(f.idMedecin)))} columns={columns}>
    </Table>
    </>
)
}

export default Statistic