import { Button, Input, Modal, Form, Space, Table, Checkbox, FloatButton, DatePicker, Popconfirm, Select, Popover, Skeleton, Tag } from "antd"
import {DeleteOutlined,UserAddOutlined,EditOutlined} from '@ant-design/icons';
import { useEffect, useState, useContext } from "react";
import { UserContext } from './App';
import 'dayjs/locale/fr';
import io from 'socket.io-client';

function Dispo(props){
  if(props.dispo=="non")return <span style={{color:'red',marginLeft:'25%'}}>Non disponible</span>
  if(props.dispo=="oui")return <span style={{color:'green',marginLeft:'25%'}}>Disponible</span>
  return <></>
}

function Profils(){
  
  const [medecins, setMedecins] = useState([])
  const [form] = Form.useForm();
  const { profils,host,cas,user } = useContext(UserContext)
  const columns = [
    {
      title: 'Nom',
      dataIndex: 'nom',
      key: 'nom',
      filterSearch: true,
      filters:medecins?medecins.map(m=>{return{text:m.nom+" "+m.prenom,value:m.nom+" "+m.prenom}}):[],
      onFilter: (value, record) => (record.nom+" "+record.prenom).indexOf(value) === 0,
    },
    {
      title: 'Prenom',
      dataIndex: 'prenom',
      key: 'prenom',
    },  
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Profil',
      dataIndex: 'profil',
      key: 'profil',
      filters:profils?profils.map(m=>{return ({text:m.label,value:m.value})}):[],
      onFilter: (value, record) => record.profil.indexOf(value) === 0,

      render:(text)=><>{profils.find(f=>f.value==text).label}</>
    },
    {
      title:'Nb cas',
      sorter:(a,b)=>patients.filter(f=>f.id_medecin==a.id).length-patients.filter(f=>f.id_medecin==b.id).length,
      render:(record)=><>
        <Tag color="green">traité{patients.filter(f=>f.id_medecin==record.id && !f.active).length} </Tag>
        <Tag color="yellow">en cours{patients.filter(f=>f.id_medecin==record.id && f.active).length} </Tag>
      </>
    },
    {
        title:'Modifier',
        key:'modifier',
        render: (_,text) => <Space>
            <Popover content={<>Modifier</>}> <Button icon={<EditOutlined />} onClick={(e)=>{setModifierId(text.id);setOuvertEdit(true)}}></Button></Popover>
            <Popover content={<>Supprimer</>}><Popconfirm title="supprimer" description="vouler vous suprimer?" okText="supprimer" cancelText="Non" onConfirm={()=>{supprimer(text.id)}}><Button danger icon={<DeleteOutlined />}></Button></Popconfirm> </Popover>
        </Space>,
    }
  ];
  
    const [ouvertNew, setOuvertNew] = useState(false)
    const [ouvertEdit, setOuvertEdit] = useState(false)
    const [modifierId, setModifierId] = useState(0)
    const [dispo, setDispo] = useState("")
    const [username, setUsername] = useState('')
    const [nom, setNom] = useState('')
    const [patients, setPatients] = useState([])
    const [prenom, setPrenom] = useState('')
    const [wait, setWait] = useState(true)
    
    const socket=io()
    function charger(){
      fetch(host+"/lireMedecins")
      .then(res => res.json())
      .then(data => {setMedecins(data.sort((x,y)=>(profils.findIndex(f=>f.value==x.profil)-profils.findIndex(f=>f.value==y.profil))).filter(f=>user.profil.substr(0,3)=='res'?f.profil=="interne":true));console.log(data);setWait(false)})
      .catch(err => console.log(err));
      
      fetch(host+"/lirePatientsAtr/")
      .then(res => res.json())
      .then(data => {setPatients(data);console.log(data);})
      .catch(err => console.log(err));
    
    }
    useEffect(() => {
      charger()
      socket.on("MAJ",()=>{
        charger()
      })
     
    }, [])

    useEffect(() => {
      form.setFieldsValue( {...medecins.find(f=>f.id==modifierId),...{password:''}})
      console.log(modifierId,{...medecins.find(f=>f.id==modifierId),...{password:''}})
    
      return () => {
        
      }
    }, [modifierId])
      
    useEffect(() => {
      setUsername(nom+prenom)
    
    }, [nom,prenom])
    
    function verifier(val){
      if(val=="")setDispo('')
      else if(medecins && medecins.some(el=>el.username==val))
      setDispo('non')
      else setDispo('oui')
    }

    function supprimer(id){
      fetch(host+"/suppMedecins/"+id)
      .then(res => res.text())
      .then(data => {socket.emit("MAJ")})
      .catch(err => console.log(err));
    }    
    
    
    const ajouter = (values) => {
        fetch(host+"/ajoutMedecin",{method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(values)
        }).then(res => res.text())
        .then(data => {alert(data);socket.emit("MAJ");setOuvertNew(false)})
        .catch(err => alert(err));
        console.log('Success:', values);
        setOuvertNew(false)
    };

    const modifier=(values)=>{
      
        fetch(host+"/editMedecin",{method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(values)
        }).then(res => res.text())
        .then(data => {alert(data);socket.emit("MAJ");setOuvertEdit(false)})
        .catch(err => console.log(err));
        console.log('Success:', values);
        
    }
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return(wait?<Skeleton active/> :<>
    
    <Space
    direction="vertical"
    size="middle"
    style={{
      display: 'flex',
    }}
    >
    
    
    <Table 
      dataSource={medecins}
      columns={columns}
      expandable={{expandedRowRender:(record)=>patients.filter(f=>f.id_medecin==record.id)
        .map(el=><Tag color={el.active?"yellow":"green"}>{cas.data[el.cas-0].cas} </Tag>)}} 
    />
    </Space>
    <Modal title="Nouveau Medecin" open={ouvertNew}  footer={null} onCancel={()=>setOuvertNew(false)}>
    <Form
    name="basic"
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 16,
    }}
    style={{
      maxWidth: 600,
    }}
    
    onFinish={ajouter}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    <Form.Item
      label="Nom"
      name="nom"
      rules={[
        {
          required: true,
          message: 'Veuillez remplir le champs',
        },
      ]}
    >
      <Input onChange={(e)=>setPrenom(e.target.value)} />
    </Form.Item>
    <Form.Item
      label="Prenom"
      name="prenom"
      rules={[
        {
          required: true,
          message: 'Veuillez remplir le champs',
        },
      ]}
    >
      <Input onChange={(e)=>setPrenom(e.target.value)} />
    </Form.Item>

    <Dispo dispo={dispo} />
    <Form.Item
      label="UserName"
      name="username"
      rules={[
        {
          required: true,
          message: 'Veuillez remplir le champs',
        },
      ]}
    >
      <Input onChange={(e)=>{verifier(e.target.value)}} />
    </Form.Item>

    <Form.Item
      label="Profile"
      name="profil"
      rules={[
        {
          required: true,
          message: 'Veuillez remplir le champs',
        },
      ]}
    >
      <Select options={profils} />
    </Form.Item>
    
    <Form.Item
      label="Mot de passe"
      name="password"
      rules={[
        {
          required: true,
          message: 'Veuillez remplir le champs',
        },
      ]}
    >
      <Input.Password />
    </Form.Item>

    
    <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>
    </Modal>

    <Modal title="Modifier Medecin" open={ouvertEdit} footer={null}  onCancel={()=>setOuvertEdit(false)}>
    <Form
    form={form}
    name="basic"
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 16,
    }}
    style={{
      maxWidth: 600,
    }}
    
    initialValues={medecins.find(e=>e.id==modifierId)}
    onFinish={modifier}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    <Form.Item
      label="Id"
      name="id"
    >
      <Input disabled />
    </Form.Item>
    <Form.Item
      label="Nom"
      name="nom"
      rules={[
        {
          required: true,
          message: 'Veuillez remplir le champs',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Prenom"
      name="prenom"
      rules={[
        {
          required: true,
          message: 'Veuillez remplir le champs',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="UserName"
      name="username"
      rules={[
        {
          required: true,
          message: 'Veuillez remplir le champs',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Profile"
      name="profil"
      rules={[
        {
          required: true,
          message: 'Veuillez remplir le champs',
        },
      ]}
    >
      <Select options={profils} />
    </Form.Item>
    
    <Form.Item
      label="Mot de passe"
      name="password"
      rules={[
        {
          required: true,
          message: 'Veuillez remplir le champs',
        },
      ]}
    >
      <Input.Password />
    </Form.Item>

    <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>
    </Modal>

    <FloatButton type="primary" icon={<UserAddOutlined/>} onClick={(e)=>setOuvertNew(true)}></FloatButton>
    </>)
}

export default Profils