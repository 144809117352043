import { Button, Input, Modal, Form, Space, Table, Checkbox, FloatButton, DatePicker, Popconfirm, Select, Popover, Skeleton } from "antd"
import {DeleteOutlined,UserAddOutlined,EditOutlined} from '@ant-design/icons';
import { useEffect, useState, useContext } from "react";
import { UserContext } from './App';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import io from 'socket.io-client';

dayjs.locale('fr');


  
function Patients(){
  
  const [form] = Form.useForm();
  const [patients, setPatients] = useState([])
  const [medecins, setMedecins] = useState([])
  const columns = [
    {
      title: 'Nom',
      dataIndex: 'nom',
      key: 'nom',
      fixed: 'left',
      filterSearch: true,
      filters:patients?patients.map(m=>{return{text:m.nom+" "+m.prenom,value:m.nom+" "+m.prenom}}):[],
      onFilter: (value, record) => (record.nom+" "+record.prenom).indexOf(value) === 0,
    },
    {
      title: 'Prenom',
      dataIndex: 'prenom',
      key: 'prenom',
      fixed: 'left'
    },
    {
      title: 'Adresse',
      dataIndex: 'adresse',
      key: 'address',
    },{
        title: 'Date de naissance',
        dataIndex: 'date_naissance',
        key: 'date_naissance',
        render:(val)=><>{new Date(val).toLocaleDateString()}</>
      },{
        title: 'Etat general',
        dataIndex: 'etat_general',
        key: 'etat_general',
      },{
        title: 'Telephone',
        dataIndex: 'telephone',
        key: 'telephone',
      },{
        title: 'Cas',
        dataIndex: 'cas',
        key: 'cas',
        render:(text)=><>{text?cas.data[text-0].cas:""}</>
      },{
        title:"medecin",
        render:(row)=>(row.id_medecin===null || medecins.length==0)?"":("dr "+medecins.find(f=>f.id==row.id_medecin).nom+" "+medecins.find(f=>f.id==row.id_medecin).prenom)
      },{
        title:'Inscrit',
        dataIndex:'inscri',
        key:'inscri',
        render:(dt)=><>{new Date(dt).toLocaleDateString()}</>
      },
    {
        title:'Modifier',
        key:'modifier',
        render: (_,text) => <Space>
            <Popover content={<>Modifier</>}> <Button icon={<EditOutlined />} onClick={(e)=>{setModifierId(text.id);setOuvertEdit(true)}}></Button></Popover>
            <Popover content={<>Supprimer</>}> <Popconfirm title="supprimer" description="vouler vous supprimer?" okText="supprimer" cancelText="Non" onConfirm={()=>{supprimer(text.id)}}><Button danger icon={<DeleteOutlined />}></Button></Popconfirm> </Popover>
        </Space>,
    }
  ];
    const { host,cas,profils } = useContext(UserContext)
    const [ouvertNew, setOuvertNew] = useState(false)
    const [ouvertEdit, setOuvertEdit] = useState(false)
    const [modifierId, setModifierId] = useState(0)
    const [wait, setWait] = useState(true)

    const socket=io()
    
    function charger(){
      //alert(host)
      fetch(host+"/lireMedecins")
        .then(res => res.json())
        .then(data => {setMedecins(data);console.log("medecins",data);setWait(false)})
        .catch(err => console.log(err));
      fetch(host+"/lireNvPatients")
        .then(res => res.json())
        .then(data => {setPatients(data);console.log(data);setWait(false)})
        .catch(err => console.log(err));
      //console.log(cas)
    }
    useEffect(() => {
      charger()
      //alert('autre')
      socket.on('MAJ',()=>{
        
        charger()
      })
       
     
    }, [])
    
    useEffect(() => {
      form.setFieldsValue( {...patients.find(e=>e.id==modifierId),...{cas:patients.find(e=>e.id==modifierId) && patients.find(e=>e.id==modifierId).cas-0} } )
      //console.log( {...patients.find(e=>e.id==modifierId),...{date_naissance:patients.find(e=>e.id==modifierId) && patients.find(e=>e.id==modifierId).date_naissance.substring(0,10)}} )
    
      return () => {
        
      }
    }, [modifierId])
  
      
    function supprimer(id){
      fetch(host+"/suppPatients/"+id)
      .then(res => res.text())
      .then(data => {charger();socket.emit('MAJ');})
      .catch(err => console.log(err));
    }    
    
    
    const ajouter = (values) => {
        values.date_naissance=new Date(values.date_naissance).toISOString().substring(0,10)
        fetch(host+"/ajoutPatient",{method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(values)
        }).then(res => res.text())
        .then(data => {charger();socket.emit('MAJ');setOuvertNew(false)})
        .catch(err => console.log(err));
        console.log('Success:', values);
        setOuvertNew(false)
    };

    const modifier=(values)=>{
      values.date_naissance=new Date(values.date_naissance).toISOString().substring(0,10)
        fetch(host+"/editPatient",{method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(values)
        }).then(res => res.text())
        .then(data => {charger();socket.emit('MAJ');setOuvertEdit(false)})
        .catch(err => console.log(err));
        console.log('Success:', values);
        
    }
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return(wait?<Skeleton active/> :<>
    
    <Space
    direction="vertical"
    size="middle"
    style={{
      display: 'flex',
    }}
    >
    
    <Table dataSource={patients} columns={columns} />
    </Space>
    <Modal title="Nouveau Patient" open={ouvertNew}  footer={null} onCancel={()=>setOuvertNew(false)}>
    <Form
    name="basic"
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 16,
    }}
    style={{
      maxWidth: 600,
    }}
    
    onFinish={ajouter}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    <Form.Item
      label="Nom"
      name="nom"
      rules={[
        {
          required: true,
          message: 'Please input your nom!',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Prenom"
      name="prenom"
      rules={[
        {
          required: true,
          message: 'Please input your prenom!',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Adresse"
      name="adresse"
      rules={[
        {
          required: true,
          message: 'Please input your address!',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Date de Naissance"
      name="date_naissance"
      rules={[
        {
          required: true,
          message: 'Please input your date de naissance!',
        },
      ]}
    >
      <Input type="date"/>
    </Form.Item>
    
    <Form.Item
      label="Telephone"
      name="telephone"
      rules={[
        {
          required: true,
          message: 'Please input your telephone!',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Etat General"
      name="etat_general"
      rules={[
        {
          required: true,
          message: 'Please input your etat general!',
        },
      ]}
    >
      <Input />

    </Form.Item>
    
    <Form.Item
      label="Cas obligatoire"
      name="cas"
      rules={[
        {
          required: true,
          message: 'Veuillez remplir ce champs!',
        },
      ]}
    >
      <Select 
      showSearch
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      
      options={profils.filter((x,i)=>i>1).map(m=>({label:m.label,options:cas.data.filter(f=>f.profil==m.value).map(e=> ({label:e.cas,value:e.id,key:e.id}))}))} />
    </Form.Item>

    <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>
    </Modal>

    <Modal title="Modifier Patient" open={ouvertEdit} footer={null}  onCancel={()=>setOuvertEdit(false)}>
    <Form
    form={form}
    name="basic"
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 16,
    }}
    style={{
      maxWidth: 600,
    }}
    
    initialValues={patients.find(e=>e.id==modifierId)}
    onFinish={modifier}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    <Form.Item
      label="Id"
      name="id"
    >
      <Input disabled />
    </Form.Item>
    <Form.Item
      label="Nom"
      name="nom"
      rules={[
        {
          required: true,
          message: 'Please input your nom!',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Prenom"
      name="prenom"
      rules={[
        {
          required: true,
          message: 'Please input your prenom!',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Adresse"
      name="adresse"
      rules={[
        {
          required: true,
          message: 'Please input your address!',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Date de Naissance"
      name="date_naissance"
      rules={[
        {
          required: true,
          message: 'Please input your date de naissance!',
        },
      ]}
    >
      <Input type="date"/>
    </Form.Item>
    
    <Form.Item
      label="Telephone"
      name="telephone"
      rules={[
        {
          required: true,
          message: 'Please input your telephone!',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Etat General"
      name="etat_general"
      rules={[
        {
          required: true,
          message: 'Please input your etat general!',
        },
      ]}
    >
      <Input />
    </Form.Item>
    
    <Form.Item
      label="Cas obligatoire"
      name="cas"
      rules={[
        {
          required: true,
          message: 'Veuillez remplir ce champs!',
        },
      ]}
    >
      <Select 
        showSearch
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={profils.filter((x,i)=>i>1).map(m=>({label:m.label,options:cas.data.filter(f=>f.profil==m.value).map(e=> ({label:e.cas,value:e.id,key:e.id}))}))} />
    </Form.Item>

    <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>
    </Modal>

    <FloatButton type="primary" icon={<UserAddOutlined/>} onClick={(e)=>setOuvertNew(true)}></FloatButton>
    </>)
}

export default Patients