import { Alert, Button, Checkbox, Form, Input } from 'antd';
import { useContext, useState } from 'react';
import { UserContext } from './App';

function Login () {
    const { user, updateUser,host } = useContext(UserContext)
    const [message, setMessage] = useState("")

const onFinish = (values) => {
  console.log('Success:', values);
  fetch(host+"/login",{method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(values)
 }).then(res => res.json())
 .then(data => {updateUser(data);console.log(data);if(data.erreur)setMessage("erreur");else setMessage("")})
 .catch(err => console.log(err));
};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

    return(
    <>
    
  <Form
    name="basic"
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 16,
    }}
    style={{
      maxWidth: 600,
    }}
    initialValues={{
      remember: true,
    }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    <Form.Item
      label="Username"
      name="username"
      rules={[
        {
          required: true,
          message: 'Please input your username!',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Password"
      name="password"
      rules={[
        {
          required: true,
          message: 'Please input your password!',
        },
      ]}
    >
      <Input.Password />
    </Form.Item>
    {message && <Alert type='warning' message={message} />}

    <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>
  </>
)}
export default Login;
