import { Button, Col, FloatButton, Form, Input, Popconfirm, Popover, Radio, Row, Select, Skeleton, Space, Table, Tabs, Tag } from "antd"
import { Content } from "antd/es/layout/layout"
import { useContext, useEffect, useState } from "react"
import { UserContext } from "./App"
import {DeleteOutlined} from '@ant-design/icons';
import { io } from "socket.io-client";

export default function Attribuer(){
    
    const {cas,host,profils,user}= useContext(UserContext)

    const [patientsAtt, setPatientsAtt] = useState([])
    const [PatientsAtr, setPatientsAtr] = useState([])
    const [medecins, setMedecins] = useState([])
    const [selectedMed, setSelectedMed] = useState(-1)
    const [selectedPat, setSelectedPat] = useState(-1)

    const columnsPat=[
        {
            title:'Patients',
            render:(row)=>row.nom+" "+row.prenom
        },
        {
          title:'cas',   
          filters: PatientsAtr.filter(f=>f.id_medecin==selectedMed).length ? PatientsAtr.filter(f=>f.id_medecin==selectedMed).map(m=>({text:cas.data[m.cas-0].cas,value:m.cas})): [],
          filterSearch: true,
          render:(row)=> cas.data[row.cas-0].cas,
          onFilter: (value, record) => record.cas==value,
          sorter: (a, b) => a.cas - b.cas,
        },
        {
            title:'enlever',
            sorter:(a,b)=>a.active-b.active,
            render:(row)=>(row.active?<Popover content={<>Supprimer</>}> <Popconfirm title="supprimer" description="vouler vous supprimer?" okText="supprimer" cancelText="Non" onConfirm={()=>DeAtt(row.id)}><Button danger icon={<DeleteOutlined />}></Button></Popconfirm></Popover>:<Tag color="green">traité</Tag>) 
        }
    ]
    const columnsMed = [
        {
            title:'selectioné',
            render:(row)=><Radio checked={row.id==selectedMed} />
        },
        {
          title: 'Medecin',
          render:(row)=>row.nom+" "+row.prenom
        },
        {
            title: 'profile',
            filters:profils?profils.map(m=>{return ({text:m.label,value:m.value})}):[],
            render:(row)=> profils.find(f=>f.value==row.profil).label,
            onFilter: (value, record) => record.profil.indexOf(value) === 0,
            sorter: (a, b) => a.profil.length - b.profil.length,
        },
        {
            title:'nb Patients',
            sorter:(a,b)=>PatientsAtr.filter(f=>f.id_medecin==a.id).length-PatientsAtr.filter(f=>f.id_medecin==b.id).length,
            render: (med)=><>
              <Tag color="yellow">en cours: {PatientsAtr.filter(f=>(f.id_medecin==med.id && f.active)).length}</Tag>
              <Tag color="green">traités: {PatientsAtr.filter(f=>(f.id_medecin==med.id && !f.active)).length}</Tag>
            </>
        }
    ]
    
    const socket=io()
  
    function charger(){
        fetch(host+"/lirePatientsAtt")
        .then(res => res.json())
        .then(data => {setPatientsAtt(data);})
        .catch(err => console.log(err));

        fetch(host+"/lireMedecins")
        .then(res => res.json())
        .then(data => {setMedecins(data.filter(f=>user.profil.substr(0,3)=='res'?f.profil=="interne":f));})
        .catch(err => console.log(err));

        fetch(host+"/lirePatientsAtr")
        .then(res => res.json())
        .then(data => {setPatientsAtr(data);})
        .catch(err => console.log(err));
    }
    useEffect(() => {
      charger()
      socket.on("MAJ",()=>{
        charger()
      })
      console.log(cas.data?cas.data.map(m=>m.cas):[])
    
    }, [])
    function DeAtt(idpat){
        var values={id:idpat,id_medecin:'null'}
        fetch(host+"/AttrPatient",{method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(values)
        }).then(res => res.text())
        .then(data => {charger();socket.emit("MAJ");})
        .catch(err => console.log(err));
        console.log('Success:', values);
        
    }

    const onFinish = (values) => {
        console.log('Success:', values);
          setSelectedPat(values.selectedPat)
          //alert(patientsAtt(selectedPat).cas)
         values={id:values.selectedPat,id_medecin:selectedMed}
         console.log("attri",values)
        fetch(host+"/AttrPatient",{method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(values)
        }).then(res => res.text())
        .then(data => {charger();socket.emit("MAJ");setSelectedPat(-1);setSelectedMed(-1)})
        .catch(err => console.log(err));
        console.log('Success:', values);

      };
      
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    
        
        
        
    

    return(
    <>
    <Space direction="vertical" size="large"
    style={{
      display: 'flex',
      width:'100%'
    }}>
        
        <Form
    name="basic"
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 16,
    }}
    style={{
      maxWidth: 800,
    }}
    initialValues={{
      remember: true,
    }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    <Form.Item
      label="Patients"
      name="selectedPat"
      rules={[
        {
          required: true,
          message: 'Please input your username!',
        },
      ]}
    >
     <Select options={patientsAtt.map(e=>{ return{value:e.id,label:e.nom+" "+e.prenom+": "+cas.data[e.cas-0].cas+": "+cas.data[e.cas-0].profil}})} />
    </Form.Item>

    <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Button disabled={selectedMed<0} type="primary" htmlType="submit">
        Attribuer
      </Button>
    </Form.Item>
  </Form>
        
        <Row>
            <Space direction="horizontal" size="middle"
            align="start"
            style={{
                display: 'flex',
                width:'100%'
            }}>
            
                <Table dataSource={medecins} columns={columnsMed} 
                    onRow={(record) => ({
                        onClick: () => setSelectedMed(record.id),
                    })}
                    rowClassName={(record) =>
                        record === (selectedMed>-1 ? 'ant-table-row-selected' : '')
                    } 
                />
                <div style={{border:"1px solid red"}}>
                <Table dataSource={PatientsAtr.filter(f=>f.id_medecin==selectedMed)} columns={columnsPat} />
                </div>
                
            </Space>
        </Row>
    </Space>
    </>)

    const données=[]
    const column=cas.data.map(m=>({
      title:m.cas,
      dataIndex: m.cas,
      key: m.cas,
    }))
    const grille=(<>
    <Table dataSource={données} columns={column} />
    </>)

    
}