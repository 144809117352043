import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
  DiffOutlined,
  VideoCameraOutlined,
  LogoutOutlined,
  LoginOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  ContactsOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import { Layout, Menu, theme,Button, Modal, Form, Input, Badge } from 'antd';
import React, { useEffect, useState } from 'react';
import { Outlet,Link, useNavigate, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from './App';
const { Header, Content, Footer, Sider } = Layout;

const items = [
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  BarChartOutlined,
  CloudOutlined,
  AppstoreOutlined,
  TeamOutlined,
  ShopOutlined,
].map((icon, index) => ({
  key: String(index + 1),
  icon: React.createElement(icon),
  label: `nav ${index + 1}`,
}));
const Layoutt = () => {
  const location=useLocation();
  
  

  const { host, user, updateUser,profils } = useContext(UserContext)
  const [ouvert, setOuvert] = useState(false)
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  function onFinish(values){
    fetch(host+"/changePass",{method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({...values,...{username:user.username}})
        }).then(res => res.text())
        .then(data => {console.log(data);if(data=="vrai"){alert("mot de passe changé");setOuvert(false)}else alert("erreur")})
        .catch(err => console.log(err));
  }
  const navigate=useNavigate()
  useEffect(()=>{
    fetch(host+"/testconnexion")
    .then(res => res.json())
    .then(data => {console.log(data);})
    .catch(err => console.log(err));
  },[location])
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const handleLogout = () => {
    console.info("test")
    fetch(host+'/logout')
      .then(res => res.text())
      .then(data => {
        console.log(data);
        updateUser({username:"",profil:""}); // Mettre à jour le contexte avec l'utilisateur déconnecté
        navigate("/login")
      }).then(data=>console.log(user))
      .catch(err => console.log(err));
  };
  return (
    <Layout style={{height:'100%',overflow:'scroll'}} hasSider>
      <Sider
      breakpoint='sm'
      style={{
        overflow: 'auto',
        height: '100vh',
        minHeight: '100vh',
        left: 0,
        top: 0,
        bottom: 0,
      }}
        
      >
        <div
          style={{
            height: 43,
            margin: 16,
            background: 'rgba(255, 255, 255, 0.2)',
            color:'white',
            textAlign:'center'
          }}
        ><img src={process.env.PUBLIC_URL + './CPMan.ico'} /> CPMan (Clinical Pedagogical Manager)</div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['4']} >
          <Menu.Item key="home" icon={<HomeOutlined />}><Link to="/">Home</Link></Menu.Item>
          {user.username && <>
          {(user.profil=="admin" || user.profil=="maitre"  || user.profil=="resident1" || user.profil=="resident2" || user.profil=="resident3" || user.profil=="resident4") && <Menu.Item key="planning" icon={<CalendarOutlined />}><Link to="/planning">Planning</Link></Menu.Item>}
          <Menu.Item key="monPlanning" icon={<CalendarOutlined />}><Link to="/monPlanning">Mon Planning</Link></Menu.Item>
          {user.profil=="admin" && <Menu.Item key="profils" icon={<UserOutlined />}><Link to="/profils">Profils</Link> </Menu.Item>}
          {(user.profil=="admin" || user.profil=="maitre"  || user.profil=="resident1" || user.profil=="resident2" || user.profil=="resident3" || user.profil=="resident4") && <Menu.Item key="patients" icon={<UserOutlined />}><Link to="/patients">Nouveaux Patients</Link> </Menu.Item>}
          {(user.profil=="admin" || user.profil=="maitre"  || user.profil=="resident1" || user.profil=="resident2" || user.profil=="resident3" || user.profil=="resident4") && <Menu.Item key="attribuer" icon={<DiffOutlined />}><Link to="/attribuer">Attribuer Patients</Link> </Menu.Item>}
          {(user.profil=="admin" || user.profil=="maitre") && <Menu.Item key="statistic" icon={<DiffOutlined />}><Link to="/statistic">Statistics</Link> </Menu.Item>}
          </>}
        </Menu>
      </Sider>
      <Layout
        className="site-layout"
        
      >
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        ><div style={{float:'right'}}>
          { user.username?
          <><b>{user.profil=="admin"?"Pr":"Dr"} {user.nom+' '+user.prenom+' '+ profils[profils.findIndex(f=>f.value==user.profil)].label}</b>
            <Button onClick={()=>setOuvert(true)} type='text'>Changer mot de pass</Button>
            <Button onClick={handleLogout} type="text" icon={<LogoutOutlined />}>logOut</Button>
          </>:
            
              <Link to="/login"><Button type="text" icon={<LoginOutlined />}>logIn</Button></Link>
            }
          </div>
        </Header>
        <Modal title="Changer le mot de pass" open={ouvert}  footer={null} onCancel={()=>setOuvert(false)}>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Mot de pass ancien"
            name="oldPassword"
            rules={[
              {
                required: true,
                message: 'Please input your username!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="nouveau Mot de pass"
            name="newPassword"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
        </Modal>
        <Content
        
          style={{
            margin: '24px 16px 0',
            overflowX:'auto'
          }}
        >
          <div
            style={{
              padding: 24,
              textAlign: 'center',
              background: colorBgContainer,
            }}
          >
            
            <Outlet />
          </div>
        </Content>
        
      </Layout>
    </Layout>
  );
};
export default Layoutt;