import * as React from "react";
import { useState,useEffect } from "react";
import { Routes, Route, Outlet, Link, BrowserRouter,useLocation } from "react-router-dom";
import { DatePicker, Image, Input } from 'antd';
import Layoutt from "./Layoutt";
import Planning from "./Planning";
import Login from "./Login";
import Profils from "./Porfils";
import { createContext } from 'react';
import Patients from "./Patients";
import cass from './cas.json'
import Attribuer from "./attribuer";
import MonPlanning from "./MonPlanning";
import Statistic from "./statistic";


export const UserContext = createContext({});

export default function App() {
  const host="http://localhost:5000"; //http://localhost:5000
  const profils=[
    {
      value:'admin',
      label:'Administrateur'
    },
    {
      value:'maitre',
      label:'Enseignant'
    },
    {
      value:'resident1',
      label:'Résident 1'
    },{
      value:'resident2',
      label:'Résident 2'
    },{
      value:'resident3',
      label:'Résident 3'
    },{
      value:'resident4',
      label:'Résident 4'
    },
    {
      value:'interne',
      label:'Interne'
    },{
      value:'autre',
      label:'autre'
    },
  ]

  const [user, setUser] = useState({idMed:-1,username:"",profil:""});
  const [cas, setCas] = useState({data:cass.data.map((e,i)=>({...e,...{id:i}}))})
  useEffect(() => {
    fetch(host+"/testconnexion").then(res => res.json())
 .then(data => {updateUser(data);console.log("test ",data);setUser(data)})
 .catch(err => console.log(err));
  console.log("cas",cas);
  }, [])
  
  
  //const searchParams = new URLSearchParams(location.search);
  //setPara(searchParams.get("name"))
  const updateUser = (name) => {
    setUser(name);
  };
  return (
    <UserContext.Provider value={ {user,updateUser,cas,profils,host} }>
    <div>
      
       <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layoutt />}>
          <Route index element={<Home />} />
          <Route path="login" element={<Login/>} />
          {user.username && <>
          {user.profil=="admin" && <Route path="profils" element={<Profils />}/>}
          {user.profil=="admin" && <Route path="statistic" element={<Statistic />}/>}
          {(user.profil=="admin" || user.profil=="maitre"  || user.profil=="resident1" || user.profil=="resident2" || user.profil=="resident3" || user.profil=="resident4") && <Route path="patients" element={<Patients />}/>}
          {(user.profil=="admin" || user.profil=="maitre"  || user.profil=="resident1" || user.profil=="resident2" || user.profil=="resident3" || user.profil=="resident4") && <Route path="planning" element={<Planning/>} />}
          <Route path="monPlanning" element={<MonPlanning/>} />
          {(user.profil=="admin" || user.profil=="maitre" || user.profil=="resident1" || user.profil=="resident2" || user.profil=="resident3" || user.profil=="resident4") && <Route path="attribuer" element={<Attribuer/>} />}
          </>
          }

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
      </BrowserRouter>
    </div>
    </UserContext.Provider>
  );
}



function Home() {
  return (
    <div>
      <h2>Home</h2>
      
      <h1>BienVenue</h1>
      <Image src={process.env.PUBLIC_URL + './CPMan.png'} />

      
    </div>
  );
}


function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
    
  );
}


