import {  Badge, Button, Calendar, Checkbox, FloatButton, Form, Input, Modal, notification, Popconfirm, Popover, Select, Tabs, Tag, Tree } from 'antd';
import 'moment/locale/fr.js';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from './App';
import {AppstoreAddOutlined,DeleteOutlined} from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { io } from 'socket.io-client';

function calculerAge(dateNaissance) {
  var differenceMs = Date.now() - dateNaissance.getTime();
  var ageDate = new Date(differenceMs); 
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

const weekDays = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
const locale = {
  lang: {
    locale: 'fr',
    month: 'Mois',
    year: 'Année',
    day: 'Jour',
    date: 'Date',
    time: 'Heure',
    am: 'AM',
    pm: 'PM',
    dateTime: 'Date/Heure',
    datePanelLabel: '{yyyy} {MM}',
    monthPanelLabel: '{yyyy}',
    weekDays
  },
  timePickerLocale: {
    placeholder: 'Sélectionner l\'heure',
  },
};

const MonPlanning = () => {
  function supprimer(id){
    
    fetch(host+"/suppSeance/"+id)
      .then(res => res.text())
      .then(data => {console.log(data);charger();socket.emit("MAJ");setOuvertSnc(false)})
      .catch(err => console.log(err));
  }

  function onFinish(values){
    values.medecin=user.idMed
    fetch(host+"/ajoutSeance",{method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(values)
        }).then(res => res.text())
        .then(data => {charger();socket.emit("MAJ");})
        .catch(err => console.log(err));
        console.log('Success:', values);
  }
function afficher(date){
  notification.open({
    message:new Date(date).toLocaleDateString(),
    description:dateCellRender(date)
  })
}
function ouvrirSnc(event){
  setselectedSnc(event)
  console.log('event',selectedSnc)
  setOuvertSnc(true)
}

function sauveRapport(){
  setSauver(true)
  fetch(host+"/editSeance",{method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({...selectedSnc,...{rapport:encodeURIComponent(selectedSnc.rapport) }})
        }).then(res => res.text())
        .then(data => {charger();socket.emit("MAJ");setSauver(false)})
        .catch(err => console.log(err));
        //console.log('Success:', values);
        setBtnInbl(true)
}

  const [seances, setSeances] = useState([])
  const [Patients, setPatients] = useState([])
  const [patient, setPatient] = useState(-1)
  const [ouvertSnc, setOuvertSnc] = useState(false)
  const [selectedSnc, setselectedSnc] = useState({})
  const { host,user,cas } = useContext(UserContext)
  const [filter, setFilter] = useState([])
  const [sauver, setSauver] = useState(false)
  const [btnInbl, setBtnInbl] = useState(true)
  const [ouvertAjou, setOuvertAjou] = useState(false)
  
  const dateCellRender = (date) => {
    const events = seances.filter(event => event.ladate.substring(0,10) === date.format('YYYY-MM-DD'))
    .filter(ev=>filter.includes(ev.idPatient));
    if (events) {
      return (
        events.map(event=>(
        <div>
        <Button danger={Object.keys(event)!=0?!Patients.find(f=>f.id==event.idPatient).active:false} onClick={()=>ouvrirSnc(event)} type='link'>{event.patient_nom+" "+event.patient_prenom}</Button>
        
      </div>))
        
      );
    } else {
      return null;
    }
  };

  const socket=io()
  
  function charger(){
    fetch(host+"/lireMesSeances/"+user.idMed)
    .then(res => res.json())
    .then(data => {setSeances(data);console.log("seance",data);})
    .catch(err => console.log(err));
    fetch(host+"/lireMesPatients/"+user.idMed)
    .then(res => res.json())
    .then(data => {setPatients(data);console.log("mes Patients",data);})
    .catch(err => console.log(err));
    
  }

  function terminer(e){
    if(e.target.checked)
      fetch(host+"/deactivePatient/"+selectedSnc.idPatient)
      .then(res => res.json())
      .then(data => {charger();socket.emit("MAJ");console.log("mes Patients",data);})
      .catch(err => console.log(err));
    else
    fetch(host+"/reactivePatient/"+selectedSnc.idPatient)
    .then(res => res.json())
    .then(data => {charger();socket.emit("MAJ");console.log("mes Patients",data);})
    .catch(err => console.log(err));
  }
  useEffect(() => {
  charger()
  socket.on("MAJ",()=>{
    charger()
  })
  console.log(user)
  }, [])
  
  const grille= (
    <>
    <Modal title={selectedSnc.ladate} footer={null}  onCancel={()=>setOuvertSnc(false)} open={ouvertSnc}>
    <p>Medecin:<b>{selectedSnc.medecin_nom+" "+selectedSnc.medecin_prenom}</b></p>
    <p>Patient:<b>{selectedSnc.patient_nom+" "+selectedSnc.patient_prenom}</b></p>
    <p>Cas:<b>{Object.keys(selectedSnc)!=0 && cas.data[selectedSnc.cas-0].cas}</b></p>
    <TextArea value={decodeURIComponent(selectedSnc.rapport)} onChange={(e)=>{setselectedSnc({...selectedSnc,...{rapport:e.target.value}});setBtnInbl(false)}}>    
    </TextArea>
    <Button disabled={btnInbl} loading={sauver} onClick={sauveRapport}>sauvegarder</Button>

    <h4>autre planning:</h4>
    {seances.filter(f=>f.idPatient==selectedSnc.idPatient && f.ladate!=selectedSnc.ladate).map(snc=>(
      <Tag color={new Date(snc.ladate)>new Date?'yellow':'green'}>{new Date(snc.ladate).toLocaleDateString()}</Tag>
    ))}
    {seances.filter(f=>f.idPatient==selectedSnc.idPatient && f.ladate!=selectedSnc.ladate).length==0?"rien":""}
    <br/>
    traité: <Checkbox checked={Object.keys(selectedSnc)!=0?!Patients.find(f=>f.id==selectedSnc.idPatient).active:false} onChange={terminer} />
    <br/>
    Supprimer cette seance<Popover content={<>Supprimer</>}> <Popconfirm title="supprimer" description="vouler vous supprimer?" okText="supprimer" cancelText="Non" onConfirm={()=>{supprimer(selectedSnc.id)}}><Button danger icon={<DeleteOutlined />}></Button></Popconfirm> </Popover>
    </Modal>

    <Modal title="Nouvelle Sceance"  footer={null} onCancel={()=>setOuvertAjou(false)} open={ouvertAjou}>
    <Form onFinish={onFinish}>
        <Form.Item
          name="patient"
          label="Patient"
          rules={[
            {
              required: true,
              message: 'Veuillez choisir le patient',
            },
          ]}
        >
        <Select onSelect={(e)=>{setPatient(e);console.log("select patient",Patients.find(f=>f.id==e))}} options={Patients?Patients.filter(f=>f.active).map(m=>{return{value:m.id,label:m.nom+" "+m.prenom}}):[]} />
        </Form.Item>
        <Form.Item
          name="date"
          label="Date"
          rules={[
            {
              required: true,
              message: 'Veuillez remplir la date!',
            },
          ]}
        >
            <Input type='date' />
        </Form.Item>
        <Form.Item>
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
    </Form>
    {patient!=-1? <>
    <p>Etat: <b> {Patients.find(f=>f.id==patient).etat_general}</b></p>
    <p>Cas:<b>{cas.data[Patients.find(f=>f.id==patient).cas-0].cas}</b></p>
    <p>l'age: <b>{calculerAge(new Date(Patients.find(f=>f.id==patient).date_naissance))} ans</b></p>
    
    
      <h4>autre planning:</h4>
      {seances.filter(f=>f.idPatient==patient).map(snc=>(
        <Tag color={new Date(snc.ladate)>new Date?'yellow':'green'}>{new Date(snc.ladate).toLocaleDateString()}</Tag>
      ))}
      {seances.filter(f=>f.idPatient==patient ).length==0?"rien":""}
      <br/></>:<></>
    }
    </Modal>
    <Select mode="multiple" allowClear style={{width: '100%',}} onChange={setFilter}
      options={Patients?[{
          label:"en cours",
          options:Patients.filter(f=>f.active==1).map(m=>{return{value:m.id,label:m.nom+" "+m.prenom}}),
        },
        {
          label:"traité",
          options:Patients.filter(f=>!f.active).map(m=>{return{value:m.id,label:m.nom+" "+m.prenom}}),
      }]:[]} 
    />
    
      <Calendar onSelect={afficher} mode={'month'} dateCellRender={dateCellRender} locale={locale} />
      
        <FloatButton icon={<AppstoreAddOutlined />} onClick={()=>setOuvertAjou(true)}>ok</FloatButton>
      
    </>
  );

  
const treeData=(Array.from(new Set(seances.map(m=>m.patient_nom+' '+m.patient_prenom+': '+cas.data[m.cas-0].cas))).map(
  m=>({
    key:m,
    title:m,
    children:seances.filter(f=>f.patient_nom+' '+f.patient_prenom+': '+cas.data[f.cas-0].cas==m).sort((x,y)=>new Date(x.ladate)-new Date(y.ladate)).map(mm=>({title:<Tag onClick={()=>ouvrirSnc(mm)} color={new Date(mm.ladate)<new Date()?'green':'yellow'}>{new Date(mm.ladate).toLocaleDateString()}</Tag>}))
  })
))
  

  const tableau=(
    <>
    <h3>la liste</h3>
    <Tree showLine treeData={treeData} />

    </>
  )

  const items = [
    {
      key: '1',
      label: 'Calendrier',
      children: grille,
    },
    {
      key: '2',
      label: 'Liste',
      children: tableau,
    }]
  return (<Tabs defaultActiveKey="1" items={items}/>)
};

export default MonPlanning;