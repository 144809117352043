import {  Button, Calendar, FloatButton, Form, Input, Modal, notification, Popover, Select, Table, Tabs, Tag, Tree } from 'antd';
import 'moment/locale/fr.js';
import { useContext, useEffect, useState } from 'react';
import {AppstoreAddOutlined,DeleteOutlined} from '@ant-design/icons';
import { UserContext } from './App';
import io from 'socket.io-client';

function calculerAge(dateNaissance) {
  var differenceMs = Date.now() - dateNaissance.getTime();
  var ageDate = new Date(differenceMs); 
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

const weekDays = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
const locale = {
  lang: {
    locale: 'fr',
    month: 'Mois',
    year: 'Année',
    day: 'Jour',
    date: 'Date',
    time: 'Heure',
    am: 'AM',
    pm: 'PM',
    dateTime: 'Date/Heure',
    datePanelLabel: '{yyyy} {MM}',
    monthPanelLabel: '{yyyy}',
    weekDays
  },
  timePickerLocale: {
    placeholder: 'Sélectionner l\'heure',
  },
};

const Planning = () => {

  const [ouvertAjou, setOuvertAjou] = useState(false)
  const [patient, setPatient] = useState(-1)
  const [medecin, setMedecin] = useState(-1)
  function onFinish(values){
    fetch(host+"/ajoutSeance",{method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(values)
        }).then(res => res.text())
        .then(data => {charger();socket.emit("MAJ");})
        .catch(err => console.log(err));
        console.log('Success:', values);
  }
  function afficher(date){
    notification.open({
      message:new Date(date).toLocaleDateString(),
      description:dateCellRender(date)
    })
  }
  function ouvrirSnc(event){
    setSelectedSnc(event)
    setOuvertSnc(true)
  }

  const [seances, setSeances] = useState([])
  const [Patients, setPatients] = useState([])
  const [Medecins, setMedecins] = useState([])
  const [ouvertSnc, setOuvertSnc] = useState(false)
  const [selectedSnc, setSelectedSnc] = useState({})
  const { host,user,cas } = useContext(UserContext)
  
  const dateCellRender = (date) => {
    const events = seances.filter(event => event.ladate.substring(0,10) === date.format('YYYY-MM-DD'))
    
    if (events) {
      return (
        events.map(event=>(
        <div>
        <Button onClick={()=>ouvrirSnc(event)} type='link'>{event.medecin_nom+" "+event.medecin_prenom}</Button>
        
      </div>))
        
      );
    } else {
      return null;
    }
  };

  /*const socket=io("http://localhost:5000", {
      "force new connection" : true,
      "reconnectionAttempts": "Infinity", 
      "timeout" : 10000,                  
      "transports" : ["websocket"],
      withCredentials:true,
          extraHeaders:{
              "my-custom-header": "abcd"
          }
  })*/
  const socket=io()
  function charger(){
    fetch(host+"/lireSeances/")
    .then(res => res.json())
    .then(data => {setSeances(data);console.log(data);})
    .catch(err => console.log(err));
    fetch(host+"/lirePatientsAtr/")
    .then(res => res.json())
    .then(data => {setPatients(data);console.log("mes Patients",data);})
    .catch(err => console.log(err));
    fetch(host+"/lireMedecins/")
    .then(res => res.json())
    .then(data => {setMedecins(data);console.log("mes Patients",data);})
    .catch(err => console.log(err));
    
  }
  useEffect(() => {
  charger()
  socket.on("MAJ",()=>{
    charger()
  })
  
  }, [])
  
  const grille= (
    <>
      <Calendar mode="week" onSelect={(date)=>afficher(date)} mode={'month'}  dateCellRender={dateCellRender} locale={locale} />
      <Modal footer={null} title="Seance" open={ouvertSnc} onCancel={()=>setOuvertSnc(false)}>
        <p>Medecin:<b>{selectedSnc.medecin_nom+" "+selectedSnc.medecin_prenom}</b></p>
        <p>Patient:<b>{selectedSnc.patient_nom+" "+selectedSnc.patient_prenom}</b></p>
        <p>Cas:<b>{Object.keys(selectedSnc)!=0 && cas.data[selectedSnc.cas-0].cas}</b></p>
        <p>rapport <b>{decodeURIComponent(selectedSnc.rapport)}</b></p>
        <h4>autre planning:</h4>
        {seances.filter(f=>f.idPatient==selectedSnc.idPatient && f.ladate!=selectedSnc.ladate).map(snc=>(
          <Tag color={new Date(snc.ladate)>new Date?'yellow':'green'}>{new Date(snc.ladate).toLocaleDateString()}</Tag>
        ))}
        {seances.filter(f=>f.idPatient==selectedSnc.idPatient && f.ladate!=selectedSnc.ladate).length==0?"rien":""}
    
      
      </Modal>
      <Modal title="Nouvelle Sceance"  footer={null} onCancel={()=>setOuvertAjou(false)} open={ouvertAjou}>
        
        <Form onFinish={onFinish}>
          <Form.Item
            name="medecin"
            label="Medecin"
            rules={[
              {
                required: true,
                message: 'Veuillez choisir le medecin',
              },
            ]}
          >
            <Select showSearch filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())} onSelect={(e)=>{setPatient(-1);setMedecin(e)}} options={Medecins?Medecins.map(m=>{return{value:m.id,label:m.nom+" "+m.prenom}}):[]} />
          </Form.Item>
          <Form.Item
            name="patient"
            label="Patient"
            rules={[
              {
                required: true,
                message: 'Veuillez choisir le patient',
              },
            ]}
          >
            <Select allowClear={true} value={patient} onSelect={(e)=>{setPatient(e);console.log("select patient",Patients.find(f=>f.id==e))}} options={Patients?Patients.filter(ff=>ff.id_medecin==medecin).filter(f=>f.active).map(m=>{return{value:m.id,label:m.nom+" "+m.prenom}}):[]} />
          </Form.Item>
          <Form.Item
            name="date"
            label="Date"
            rules={[
              {
                required: true,
                message: 'Veuillez remplir la date!',
              },
            ]}
          >
            <Input type='date' />
          </Form.Item>
          <Form.Item>
            <Button disabled={patient==-1?true:false} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
        {patient!=-1? <>
          <p>Etat: <b> {Patients.find(f=>f.id==patient).etat_general}</b></p>
          <p>Cas:<b>{cas.data[Patients.find(f=>f.id==patient).cas-0].cas}</b></p>
          <p>l'age: <b>{calculerAge(new Date(Patients.find(f=>f.id==patient).date_naissance))} ans</b></p>
    
    
          <h4>autre planning:</h4>
          {seances.filter(f=>f.idPatient==patient).map(snc=>(
            <Tag color={new Date(snc.ladate)>new Date?'yellow':'green'}>{new Date(snc.ladate).toLocaleDateString()}</Tag>
          ))}
          {seances.filter(f=>f.idPatient==patient ).length==0?"rien":""}
          <br/></>:<></>
      }
    </Modal>
    <FloatButton icon={<AppstoreAddOutlined />} onClick={()=>setOuvertAjou(true)}>ok</FloatButton>
    </>
  );
  
  const racine=Array.from(new Set(seances.map(m=>m.medecin_nom+' '+m.medecin_prenom))).map(m=>{return ({title:m})})
  const racine2=racine.map(m=>({...m,
    ...{
        children:Array.from(new Set(seances.filter(f=>f.medecin_nom+' '+f.medecin_prenom==m.title).map(mm=>mm.patient_nom+' '+mm.patient_prenom+': '+cas.data[mm.cas-0].cas)))
        .map(mm=>({title:mm,children:seances.filter(f=>f.medecin_nom+' '+f.medecin_prenom==m.title && f.patient_nom+' '+f.patient_prenom+': '+cas.data[f.cas-0].cas==mm).sort((x,y)=>new Date(x.ladate)-new Date(y.ladate)).map(mf=>{return({title:<Tag onClick={()=>ouvrirSnc(mf)} color={new Date(mf.ladate)<new Date()?'green':'yellow'}>{new Date(mf.ladate).toLocaleDateString()}</Tag>})})}))
      }} ))
  

  const tableau=(
    <>
    <h3>la liste</h3>
    <Tree showLine treeData={racine2} />

    </>
  )
  const items = [
    {
      key: '1',
      label: 'Calendrier',
      children: grille,
    },
    {
      key: '2',
      label: 'Liste',
      children: tableau,
    }]
  return (<Tabs defaultActiveKey="1" items={items}/>)
};

export default Planning;